<template>
    <div class="container-fluid vh-100 d-flex justify-content-center align-items-center"
        style="background-color: #FFFFFF;">
        <div class="card p-4 shadow" style="width: 600px; background-color: #133b5d; border-radius: 35px;">
            <img src="../assets/logo.png" alt="Logo" class="img-fluid mx-auto mb-4" style="width: 500px;">
            <div v-if="errorMessage" class="alert alert-danger" role="alert">
                {{ errorMessage }}
            </div>
            <form @submit.prevent="login">
                <div class="mb-3">
                    <label for="email" class="form-label" style="color: #FFFFFF;">Email do Usuario</label>
                    <input type="email" class="form-control" id="email"
                        v-model="data.email" required="required" placeholder="" autocomplete="email">
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label" style="color: #FFFFFF;">Senha</label>
                    <input type="password" class="form-control" id="password"
                        v-model="data.password" required="required" placeholder="" autocomplete="current-password">
                </div>
                <button type="submit" @click="onSubmit" class="btn btn-success w-100">Fazer Login</button>
            </form>
        </div>
    </div>

</template>

<script>
import { AuthEndpoints, Network, base_url } from '../application/network.js';
import axios from 'axios';
import store from '@/store/Index';

export default {
    name: 'login',
    data() {
        return {
            data: {
                email: '',
                password: '',
            },
            errorMessage: ''
        }
    },
    created() {
    },
    methods: {
        async onSubmit(event) {

            event.preventDefault();
            await Network.post(AuthEndpoints.authentication, this.data, { headers: { Accept: 'aplication/json' }, withCredentials: true, })
                .then((response) => {
                    var user = response.data.company || response.data.driver
                    var token = response.data.token
                    var role = response.data.role
                    this.$store.commit('storeUser', user)
                    this.$store.commit('storeToken', token)
                    this.$store.commit('setRole', role)
                    localStorage.setItem('logged', true)
                    this.$router.push('/profile')
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.message) {
                        this.errorMessage = err.response.data.message;
                    } else {
                        this.errorMessage = 'Ocurrió un error durante el inicio de sesión. Por favor, intenta nuevamente.';
                    }
                })
        }

    }
}
</script>
<style scoped>
.alert-danger {
    color: white;
    background-color: #e74c3c;
    border-color: #c0392b;
}
.form-control {
    border-color: #FFFFFF; 
    color:#FFFFFF;
}
</style>
