import axios from 'axios'
import store from '../store/Index'

export const AuthEndpoints = {
    authentication: '/auth',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    }
}

export const CompanyEndpoints = {
    get: '/companies/',
    create: '/companies/',
    company(id) { return `/companies/${id}` },
    deliveries(id) { return `/companies/${id}/deliveries` },
    deliveriesCount(id) { return `/companies/${id}/deliveries/count` }
}

export const SlotEndpoints = {
    get: '/slots/',
    create: '/slots/',
    delete: '/slots_delete/',
    update_slot: '/slots_update/',
    get_available(id) { return `/slots_available/${id}` },
    slots_index: '/slots_index',
    slots_driver(id) { return `/slots_driver/${id}` },
    delete_slot(id) { return `/slot_delete/${id}` }
}

export const CustomerEndpoints = {
    get: '/customers/',
    create: '/customers/',
    customer_company(id) { return `/customers_company/${id}` },
    customer(id) { return `/customers/${id}` },

}

export const DriverEndpoints = {
    get: '/drivers/',
    create: '/drivers/',
    driver(id) { return `/drivers/${id}` },
    drivers_state(id) { return `/drivers_state/${id}` },
    deliveries(id) { return `/drivers/${id}/deliveries` },
    location(id) {return `location_active/${id}`},
    socket: '/socket_connection'
}

export const FeeEndpoints = {
    get: '/fees/',
    create: '/fees/',
    fee(id) { return `/fees/${id}` },
    feeCompany(id) { return `/fees_company/${id}` },
}

export const DeliveryEndpoints = {
    getTableCompany(id){ return `/deliveries_company/${id}` },
    create: '/deliveries',
    delivery(id) {return `/deliveries/${id}`},
    cancelOrder: `/cancel_deliveries`,
    get(id) { return `/admin/deliveries/${id}` }
}

export const ServiceEndpoints = {
    get: '/services/',
    create: '/services/',
    service(id) { return `/services/${id}` },
}

export const CheckApiEndpoints = {
    get: '/deliveriesApi',
    getAllDrivers: '/get_drivers',
    getAllCompanies: '/get_companies',
    statics: `/total_statics`,
    getByOrder(id) {return `/getByOrder/${id}`},
    updateOrder(id) {return `/updateOrder/${id}`}

    //statics (id) { return `/total_statics/${id}`},
}

//DEV
//export const base_url = 'http://localhost:2000'
//QAS
//export const base_url = 'https://deliveryforyouapi-production.up.railway.app';
export const base_url = process.env.VUE_APP_BASE_URL;
console.log(base_url); 
if (process.env.NODE_ENV === 'development') {
    console.log("Estás en desarrollo");
  } else if (process.env.NODE_ENV === 'production') {
    console.log("Estás en producción");
  }
export const Network = axios.create({
    //baseURL: 'https://deliveryforyouapi-production.up.railway.app/v2/api',
    //baseURL: 'http://localhost:2000/v2/api',
    baseURL:process.env.VUE_APP_API_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'secret': 'DEL!VERY_AP1_SECRET_!2C4'
    },
    withCredentials: true
});

Network.interceptors.request.use(config => {
    const token = store.state.token;
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});